<template>
    <div class="container-fluid">
        <list-desktop
            v-if="!$isMobile()"
            :empty="empty"
            :configTs="configTs"
            :loading="loading"
            :data="data"
            :queriedData="queriedData"
            :dataPagination="dataPagination"
            v-on:add_or_edit="addOrEdit"
            v-on:search_q="searchBack"
            v-on:load_prices="loadPrices"
            v-on:delete_price="deletePrice"
            v-on:per_page_selected="nbrPerPageSelected"
            v-on:duplicate="duplicate"
            v-on:duplicate_all="duplicateAll"
        >
        </list-desktop>
        <list-mobile
            v-if="$isMobile()"
            :empty="empty"
            :configTs="configTs"
            :loading="loading"
            :data="data"
            :queriedData="queriedData"
            :dataPagination="dataPagination"
            v-on:add_or_edit="addOrEdit"
            v-on:search_q="searchBack"
            v-on:load_prices="loadPrices"
            v-on:delete_price="deletePrice"
            v-on:per_page_selected="nbrPerPageSelected"
            v-on:duplicate="duplicate"
            v-on:duplicate_all="duplicateAll"
        >
        </list-mobile>

    <mdb-modal
        centered
        size="lg"
        direction="top"
        position="top"
        :show="modalPrice.show"
        @close="modalPrice.show = false"
    > 
        <form @submit.prevent="modalPrice.edit ? update() : save()">
        <mdb-modal-header>
            <mdb-modal-title>{{ modalPrice.title }}</mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body>
            <fieldset >
                <mdb-row>
                    <mdb-col sm="12" md="4" class="mt-3">
                        <el-date-picker
                        class="w-100"
                        v-model="form.month"
                        value-format="yyyy/MM"
                        type="month"
                        placeholder="Sélectionnez un mois">
                        </el-date-picker>
                        <div  style="color:#dc3545;font-size:.8rem" class="mt-4" v-if="$store.state.price.errors.month" >{{$store.state.price.errors.month}}</div>
                    </mdb-col>
                    <mdb-col sm="12" md="8">
                        <mdb-select flipOnScroll wrapperClass=""   labelClass=" color-normal-reversed" caretClass="color-primary-reversed " 
                            search v-model="articleOption" @getValue="getArticle" outline label="Article" ></mdb-select>
                        <div  style="color:#dc3545;font-size:.8rem" v-if="$store.state.price.errors.article_id" >{{$store.state.price.errors.article_id}}</div>

                    </mdb-col>
                    <mdb-col sm="12" md="4">
                        <mdb-input 
                        :validation="$store.state.price.errors.ht ? true :false" :invalidFeedback="$store.state.price.errors.ht"
                        v-model="form.ht"
                            type="number" :min="0" step="0.0001" outline label="HT" ></mdb-input>
                    </mdb-col>
                    <mdb-col sm="12" md="4">
                        <mdb-input 
                        :validation="$store.state.price.errors.tva ? true :false" :invalidFeedback="$store.state.price.errors.tva"
                        v-model="form.tva"
                            type="number" :min="0" step="0.0001" outline label="TVA" ></mdb-input>
                    </mdb-col>
                    <mdb-col sm="12" md="4">
                        <mdb-input 
                        :validation="$store.state.price.errors.ttc ? true :false" :invalidFeedback="$store.state.price.errors.ttc"
                        v-model="form.ttc"
                            type="number" :min="0" step="0.0001" outline label="TTC" ></mdb-input>
                    </mdb-col>
                    <mdb-col sm="12" md="4">
                        <mdb-input 
                        :validation="$store.state.price.errors.ts ? true :false" :invalidFeedback="$store.state.price.errors.ts"
                        v-model="form.ts"
                            type="number" :min="0" step="0.0001" outline label="TS" ></mdb-input>
                    </mdb-col>
                    <mdb-col sm="12" md="4">
                        <mdb-input 
                        :validation="$store.state.price.errors.tax1 ? true :false" :invalidFeedback="$store.state.price.errors.tax1"
                        v-model="form.tax1"
                            type="number" :min="0" step="0.0001" outline :label="configTs.tax1" ></mdb-input>
                    </mdb-col>
                    <mdb-col sm="12" md="4">
                        <mdb-input 
                        :validation="$store.state.price.errors.tax2 ? true :false" :invalidFeedback="$store.state.price.errors.tax2"
                        v-model="form.tax2"
                            type="number" :min="0" step="0.0001" outline :label="configTs.tax2" ></mdb-input>
                    </mdb-col>
                </mdb-row>
            </fieldset>
        </mdb-modal-body>
        <mdb-modal-footer class="d-flex justify-content-end">
            <mdb-btn size="md" type="submit" color="primary"  :disabled="modalPrice.btn" >
                <span v-if="!modalPrice.btn">Sauvegarder</span>
                <span v-if="modalPrice.btn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span v-if="modalPrice.btn" class="pl-2">Loading...</span>
            </mdb-btn>
            <mdb-btn size="md" outline="primary" @click="modalPrice.show = false">Fermer</mdb-btn>

        </mdb-modal-footer>
        </form>
    </mdb-modal>
    
    <mdb-modal
        centered
        size="md"
        direction="top"
        position="top"
        :show="modalPriceDuplicate.show"
        @close="modalPriceDuplicate.show = false"
    > 
        <form @submit.prevent="saveDuplicate()">
        <mdb-modal-header>
            <mdb-modal-title >{{ modalPriceDuplicate.title }}</mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body class="modal-tec card-reversed">
            <fieldset>
                <mdb-row>
                    <mdb-col sm="12" md="12" class="mt-4 mb-4 color-normal-reversed">
                        <p v-if="modalPriceDuplicate.content" >
                            Pour dupliquer la structure de prix de l'article <b v-if="modalPriceDuplicate.content.articles">{{modalPriceDuplicate.content.articles.designation}}</b>  du mois de <b v-if="modalPriceDuplicate.content.month">  {{$moment(modalPriceDuplicate.content.month.split('/')[0]+'-'+modalPriceDuplicate.content.month.split('/')[1]).format('MMMM YYYY')}}</b> ,<br>
                            Renseigner le nouveau mois.
                        </p>
                        <el-date-picker
                        class="w-100"
                        v-model="formDuplicate.month"
                        value-format="yyyy/MM"
                        type="month"
                        placeholder="Sélectionnez un mois">
                        </el-date-picker>
                        <div  style="color:#dc3545;font-size:.8rem" class="mt-4" v-if="$store.state.price.errors.month" >{{$store.state.price.errors.month}}</div>
                    </mdb-col>
                </mdb-row>
            </fieldset>
        </mdb-modal-body>
        <mdb-modal-footer class="">
            <mdb-btn size="md" type="submit" color="primary"  :disabled="modalPriceDuplicate.btn" >
                <span v-if="!modalPriceDuplicate.btn">Dupliquer</span>
                <span v-if="modalPriceDuplicate.btn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span v-if="modalPriceDuplicate.btn" class="pl-2">Loading...</span>
            </mdb-btn>
            <mdb-btn size="md" outline="primary"  @click="modalPriceDuplicate.show = false">Fermer</mdb-btn>

        </mdb-modal-footer>
        </form>
    </mdb-modal>

    <mdb-modal
        centered
        size="md"
        direction="top"
        position="top"
        :show="modalPriceDuplicateAll.show"
        @close="modalPriceDuplicateAll.show = false"
    > 
        <form @submit.prevent="saveDuplicateAll()">
        <mdb-modal-header>
            <mdb-modal-title>{{ modalPriceDuplicateAll.title }}</mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body class="">
            <fieldset>
                <mdb-row>
                    <mdb-col sm="12" md="12" class="mt-4 mb-4">
                        <p v-if="modalPriceDuplicateAll.content" >
                            Dupliquer facilement la structure de prix de tous vos produits d'un mois vers un autre mois. <br>
                        </p>
                        <div class="form-group">
                            <label for="">De</label>
                            <el-date-picker
                            class="w-100"
                            v-model="formDuplicateAll.from"
                            value-format="yyyy/MM"
                            type="month"
                            placeholder="De">
                            </el-date-picker>
                        <div  style="color:#dc3545;font-size:.8rem" class="mt-4" v-if="$store.state.price.errors.from" >{{$store.state.price.errors.from}}</div>

                        </div>
                        
                        <div class="form-grou">
                            <label for="">Vers</label>

                            <el-date-picker
                                class="w-100"
                                v-model="formDuplicateAll.to"
                                value-format="yyyy/MM"
                                type="month"
                                placeholder="Vers">
                            </el-date-picker>
                        <div  style="color:#dc3545;font-size:.8rem" class="mt-4" v-if="$store.state.price.errors.to" >{{$store.state.price.errors.to}}</div>

                        </div>
                        

                    </mdb-col>
                </mdb-row>
            </fieldset>
        </mdb-modal-body>
        <mdb-modal-footer class="">
            <mdb-btn size="md" type="submit" color="primary"  :disabled="modalPriceDuplicateAll.bttn" >
                <span v-if="!modalPriceDuplicateAll.bttn">Dupliquer</span>
                <span v-if="modalPriceDuplicateAll.bttn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span v-if="modalPriceDuplicateAll.bttn" class="pl-2">Loading...</span>
            </mdb-btn>
            <mdb-btn size="md" outline="primary"  @click="modalPriceDuplicateAll.show = false">Fermer</mdb-btn>
        </mdb-modal-footer>
        </form>
    </mdb-modal>
    </div>
</template>

<script>
import ListDesktop from '@/tba/views/prices/modules/ListDesktop'
import ListMobile from '@/tba/views/prices/modules/ListMobile'
import {
    mdbBtn,
    mdbRow,mdbCol,
    mdbSelect,mdbInput,
    mdbModal,mdbModalTitle,mdbModalBody,mdbModalHeader,mdbModalFooter,
} from 'mdbvue'
export default {
    metaInfo: {
      title: 'TECBILL APP',
      titleTemplate: '%s - STRUCTURES DES PRIX',
      htmlAttrs: {
        lang: 'fr',
        amp: true
      }
    },
    components:{
        mdbBtn,
        mdbRow,mdbCol,
        mdbSelect,mdbInput,
        mdbModal,mdbModalTitle,mdbModalBody,mdbModalHeader,mdbModalFooter,
        ListDesktop,ListMobile
    },

    data(){
        return {
            loading:false,
            empty:false,

            configTs:{
                tax1:"Tax 1",
                tax2:"Tax 2",
            },
            dataPagination: {
                pagination: {
                    perPage: 5,
                    currentPage: 1,
                    perPageOptions: [
                        {text:"5",value:"5", selected: true},
                        {text:"10",value:"10"},
                        {text:"25",value:"25"},
                        {text:"50",value:"50"},
                        {text:"100",value:"100"},
                    ],
                    total: 0,
                },
                searchQuery: "",

                empty:false,

                propsToSearch: [
                    "month",
                    "price",
                    "ht",
                    "ttc",
                    "ts",
                    "sts",
                    "rcs",
                    "articles"
                ],
            },

            data:{
                columns: [
                    {
                        label: "Mois",
                        field: "month",
                        sort: true,
                    },
                    {
                        label: "Article",
                        field: "price",
                        sort: true,
                    },
                    {
                        label: "HT",
                        field: "ht",
                        sort: true,
                    },
                    {
                        label: "TTC",
                        field: "unit",
                        sort: true,
                    },
                    {
                        label: "TS",
                        field: "ts",
                        sort: true,
                    },

                    {
                        label: "tax1",
                        field: "tax1",
                        sort: true,
                    },

                    {
                        label: "tax2",
                        field: "tax2",
                        sort: true,
                    },
                    
                    {
                        label: "",
                        field: "action",
                        sort: true,
                    },
                ],
                rows:[]
            },

            articles:[],
            articleOption:[],

            form:{
                id:"",
                code_price:"",
                month:"",
                ht:0,
                tva:0,
                ts:0,
                ttc:0,
                tax1:0,
                tax2:0,
                article_id:"",
            },

            formDuplicate:{
                id:"",
                code_price:"",
                month:"",
                ht:0,
                tva:0,
                ts:0,
                ttc:0,
                sts:0,
                rcs:0,
                article_id:"",
            },

            formDuplicateAll:{
                from:"",
                to:""
            },
            modalPriceDetails: {
                show: false,
                edit: false,
                title: "Détails de la structure de prix",
                content: {},
            },
            modalPrice: {
                show: false,
                edit: false,
                title: "Ajouter une structure de prix",
                content: {},
                btn:false
            },
            modalPriceDuplicate: {
                show: false,
                edit: false,
                title: "Ajouter une structure de prix",
                content: {
                        content: {},
                },
                btn:false
            },
            modalPriceDuplicateAll: {
                show: false,
                edit: false,
                title: "Ajouter une structure de prix",
                content: {
                        content: {},
                },
                bttn:false
            },
        }
    },

    methods:{
        //Fonction de fonctionnement
        addOrEdit(data = null) {
            if (data instanceof Object) {
                this.modalPrice.title = "Modifier une structure";
                this.modalPrice.content = data;
                this.modalPrice.edit = true;
                this.formRest(data);
            } else {
                this.modalPrice.title = "Ajouter une structure";
                this.modalPrice.content = {};
                this.modalPrice.edit = false;
                this.cleanForm();
            }

            this.modalPrice.show = true;
        },

        async getConfigTs(){
            await this.$store.dispatch('price/getConfigTs').then((response) => {
                this.configTs = response.data
            }).catch((error) => {
                this.$notify({
                       
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
            })
        },

        duplicate(data){
            // this.modalPrice.title = "Ajouter une structure";
            this.modalPriceDuplicate.content = data;
            this.formRestDuplicate(data)
            this.formDuplicate.id = ""
            this.formDuplicate.month = ""
            this.formDuplicate.user_id = this.$store.state.auth.user.id
           
            this.modalPriceDuplicate.title = "Dupliquer une structure";
            this.modalPriceDuplicate.show = true;               
        },

        duplicateAll(){
            this.$store.commit('price/SET_CLEAN')
            this.modalPriceDuplicateAll.title = "Dupliquer les structures";
            this.modalPriceDuplicateAll.show = true;               
        },

        async loadArticles () {
			this.$nprogress.start()
            await this.$store.dispatch('article/findAll')
            .then(response => {
				this.$nprogress.done()
                this.articles = response.data.articles.filter(article => article.specific === 1)

                this.articles.forEach(article =>{
                    this.articleOption.push({
                        text:article.designation,
                        value:article.id
                    })
                })
            }).catch((error) => {
				this.$nprogress.done()
                this.$notify({
                       
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
            })
        },

        cleanForm() {
            this.form.id = ""
            this.form.code_price = ""
            this.form.month = ""
            this.form.tva = 0
            this.form.ht = 0
            this.form.ts = 0
            this.form.ttc = 0
            this.form.sts = 0
            this.form.rcs = 0
        },

        cleanFormDuplicate() {
            this.formDuplicate.id = ""
            this.formDuplicate.code_price = ""
            this.formDuplicate.month = ""
            this.formDuplicate.ht = 0
            this.formDuplicate.tva = 0
            this.formDuplicate.ts = 0
            this.formDuplicate.ttc = 0
            this.formDuplicate.sts = 0
            this.formDuplicate.rcs = 0
        },

        cleanFormDuplicateAll() {
            this.formDuplicateAll.from = ""
            this.formDuplicateAll.to = ""
        },

        formRestDuplicate(data) {
            this.formDuplicate.id = data.id
            this.formDuplicate.code_price = data.code_price
            this.formDuplicate.month = data.month
            this.formDuplicate.ht = data.ht
            this.formDuplicate.ts = data.ts
            this.formDuplicate.tva = data.tva
            this.formDuplicate.ttc = data.ttc
            this.formDuplicate.tax1 = data.tax1
            this.formDuplicate.tax2 = data.tax2
            this.formDuplicate.article_id = data.article_id
        },

        formRest(data) {
            this.form.id = data.id
            this.form.code_price = data.code_price
            this.form.month = data.month
            this.form.ht = data.ht
            this.form.ts = data.ts
            this.form.tva = data.tva
            this.form.ttc = data.ttc
            this.form.sts = data.sts
            this.form.rcs = data.rcs
            this.form.article_id = data.article_id

            this.articleOption = [];
            this.articles.forEach(article => {
                this.articleOption.push({
                    text:article.designation,
                    value:article.id,
                    selected:(article.id === data.article_id) ? true : false
                })
            })  
        },

        getArticle(value){
            this.form.article_id = value
        },

        async loadPrices () {
			this.$nprogress.start()
            this.loading = !this.loading
            await this.$store.dispatch('price/findAll')
            .then(response => {
                this.$nprogress.done()
                this.loading = !this.loading
                if (response.data.prices.length === 0) {
                    this.empty = true
                }else{
                    this.empty = false
                }

                this.data.rows = response.data.prices
            }).catch((error) => {
				this.$nprogress.done()
                this.loading = !this.loading
                this.$notify({
                       
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
            })
        },

        async save() {
            this.$store.commit('price/SET_CLEAN')
            this.modalPrice.btn = !this.modalPrice.btn
            this.form.code_price = this.$store.state.auth.company.id+"/"+this.form.month+"/"+this.form.article_id
            await this.$store.dispatch('price/save', this.form)
			.then((response) => {
				this.modalPrice.show = false
                this.modalPrice.btn = !this.modalPrice.btn
                    this.$notify({
                         
                        message: response.data.message,
                        type: 'success'
                    })
				this.cleanForm()
                this.loadPrices()
			})
			.catch((error) => {
                this.modalPrice.btn = !this.modalPrice.btn
                if (error.response.data.errors) {
                    this.$store.commit('price/SET_STAX', error.response.data.errors.ts)
                    this.$store.commit('price/SET_PHT', error.response.data.errors.ht)
                    this.$store.commit('price/SET_TVA', error.response.data.errors.tva)
                    this.$store.commit('price/SET_PTTC', error.response.data.errors.ttc)
                    this.$store.commit('price/SET_TAX1', error.response.data.errors.tax1)
                    this.$store.commit('price/SET_TAX2', error.response.data.errors.tax2)
                    this.$store.commit('price/SET_ARTICLE', error.response.data.errors.article_id)
                    this.$store.commit('price/SET_MONTH', error.response.data.errors.code_price  ? error.response.data.errors.code_price : error.response.data.errors.month)
                }
                this.$notify({
                       
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
			}) 
        },

        async saveDuplicate() {
            this.$store.commit('price/SET_CLEAN')
            this.modalPriceDuplicate.btn = !this.modalPriceDuplicate.btn
         
            this.formDuplicate.code_price = this.$store.state.auth.company.id+"/"+this.formDuplicate.month+"/"+this.formDuplicate.article_id
            await this.$store.dispatch('price/save', this.formDuplicate)
			.then((response) => {
				this.modalPriceDuplicate.show = false
                this.modalPriceDuplicate.btn = !this.modalPriceDuplicate.btn
                    this.$notify({
                         
                        message: response.data.message,
                        type: 'success'
                    })
				this.cleanFormDuplicate()
                this.loadPrices()
			})
			.catch((error) => {
                this.modalPriceDuplicate.btn = !this.modalPriceDuplicate.btn
                if (error.response.data.errors) {
                    this.$store.commit('price/SET_STAX', error.response.data.errors.ts)
                    this.$store.commit('price/SET_PHT', error.response.data.errors.ht)
                    this.$store.commit('price/SET_TVA', error.response.data.errors.tva)
                    this.$store.commit('price/SET_PTTC', error.response.data.errors.ttc)
                    this.$store.commit('price/SET_STS', error.response.data.errors.sts)
                    this.$store.commit('price/SET_RCS', error.response.data.errors.rcs)
                    this.$store.commit('price/SET_ARTICLE', error.response.data.errors.article_id)
                    this.$store.commit('price/SET_MONTH', error.response.data.errors.code_price  ? error.response.data.errors.code_price : error.response.data.errors.month)
                }
                this.$notify({
                       
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
			}) 
        },

        async saveDuplicateAll() {
            this.modalPriceDuplicateAll.bttn = !this.modalPriceDuplicateAll.bttn
            this.$store.commit('price/SET_CLEAN')
            await this.$store.dispatch('price/duplicate', this.formDuplicateAll)
			.then((response) => {
				this.modalPriceDuplicateAll.show = !this.modalPriceDuplicateAll.bttn
                this.modalPriceDuplicateAll.bttn = !this.modalPriceDuplicateAll.bttn
                    this.$notify({
                         
                        message: response.data.message,
                        type: 'success'
                    })
				this.cleanFormDuplicateAll()
                this.loadPrices()
			})
			.catch((error) => {
                this.modalPriceDuplicateAll.bttn = !this.modalPriceDuplicateAll.bttn
                if (error.response.data.errors) {
                    this.$store.commit('price/SET_FROM', error.response.data.errors.from)
                    this.$store.commit('price/SET_TO', error.response.data.errors.to)
                }
                this.$notify({
                       
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
			}) 
        },

        async update() {
            this.$store.commit('price/SET_CLEAN')
            this.modalPrice.btn = !this.modalPrice.btn
            this.form.code_price = this.$store.state.auth.company.id+"/"+this.form.month+"/"+this.form.article_id
			await this.$store.dispatch('price/update', this.form)
			.then((response) => {
				this.modalPrice.show = false
                this.modalPrice.btn = !this.modalPrice.btn
                    this.$notify({
                         
                        message: response.data.message,
                        type: 'success'
                    })
				this.cleanForm()
                this.loadPrices()
			})
			.catch((error) => {
                this.modalPrice.btn = !this.modalPrice.btn
                if (error.response.data.errors) {
                    this.$store.commit('price/SET_STAX', error.response.data.errors.ts)
                    this.$store.commit('price/SET_PHT', error.response.data.errors.ht)
                    this.$store.commit('price/SET_TVA', error.response.data.errors.tva)
                    this.$store.commit('price/SET_PTTC', error.response.data.errors.ttc)
                    this.$store.commit('price/SET_TAX1', error.response.data.errors.tax1)
                    this.$store.commit('price/SET_TAX2', error.response.data.errors.tax2)
                    this.$store.commit('price/SET_ARTICLE', error.response.data.errors.article_id)
                    this.$store.commit('price/SET_MONTH', error.response.data.errors.code_price  ? error.response.data.errors.code_price : error.response.data.errors.month)
                }
                this.$notify({
                       
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
			})  
        },

        async deletePrice(id){

            let res = await this.$store.dispatch("swal/doYouDelete");
            if (res.isDismissed) {
                return;
            }
			await this.$store.dispatch('price/delete',id)
                .then((response) => {
                    this.$notify({
                         
                        message: response.data.message,
                        type: 'success'
                    })
                    this.loadPrices()
                })
                .catch(error =>{
                    this.$notify({
                           
                        message: error.response.data.message  || error.response.data.error,
                        type: 'danger'
                    })
                })
			
		},

        // Systeme de datatable
        totalCalcul () {
            return this.dataPagination.pagination.total = this.data.rows.length;
        },

        searchQ () {
            if (!this.dataPagination.searchQuery) {
                // return this.pagedData;
                this.totalCalcul()
                return new Error('Nothing To Search')
            }
            
            let result = this.data.rows.filter((row) => {
                let isIncluded = false;

                for (let key of this.dataPagination.propsToSearch) {
                    let rowValue = "";
                    if (typeof row[key] === "object") {
                        switch (key) {
                            case "articles":  
                                rowValue = (row[key]) ? (row[key].designation).toString().toLowerCase() : '';
                                break;
                            default:
                                break;
                        }
                    }

                    if (typeof row[key] === 'string') {
                        rowValue = row[key].toString().toLowerCase();
                    }
                    if (
                        rowValue.includes &&
                        rowValue.includes(this.dataPagination.searchQuery.toLowerCase())
                        ) { 
                        isIncluded = true;
                        }
                }
                return isIncluded;
            });
            this.dataPagination.pagination.total = result.length;
            return result.slice(this.from, this.to);
        },

        nbrPerPageSelected(value){
            let nbr = value;
            
            return this.nbrPerPage(nbr)
        },

        nbrPerPage (nbr) {
           return this.dataPagination.pagination.perPage = nbr
        },
    },
    
    created() {
        this.getConfigTs()
        this.loadPrices()
        this.loadArticles()
    },

    computed: {
        pageData () {
            return this.data.rows.slice(this.from, this.to);
        },

        to() {
            let highBound = this.from + this.dataPagination.pagination.perPage;
            if (this.total < highBound) {
                highBound = this.total;
            }
            return highBound;
        },

        queriedData() {
            let result = this.searchQ();
            return (result instanceof Error) ? this.pageData : result;
        },

        from() {
            return this.dataPagination.pagination.perPage * (this.dataPagination.pagination.currentPage - 1);
        },

        total() { 
            return this.totalCalcul();
        },
    }
}
</script>