<template>
    <div>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <h4  style="font-weight:400" >Structures de prix</h4>
                <!-- <mdb-btn
                    class="float-right"
                    tag="a"
                    color="primary"
                    icon="plus"
                    size="sm" 
                    small
                    @click="addOrEdit(null)"
                >
                </mdb-btn> -->
                <div>
                    <mdb-btn 
                    v-if="$gate.hasPermission(['create::price'])"
                    
                    @click="addOrEdit(null)" icon="plus" tag="a" size="sm"  color="primary" class="m-0"></mdb-btn>
                    <mdb-dropdown btn-group split dropright>
                    <mdb-dropdown-toggle slot="toggle" color="primary" size="sm" class="px-2 py-2"></mdb-dropdown-toggle>
                    <mdb-dropdown-menu>
                        <mdb-dropdown-item @click="addOrEdit(null)"><i class="fas fa-plus"></i> Ajouter une structure</mdb-dropdown-item>
                        <mdb-dropdown-item @click.native="duplicateAll()"> <i class="fas fa-copy"></i> Dupliquer les structures</mdb-dropdown-item>
                    </mdb-dropdown-menu>
                    </mdb-dropdown>
                </div>
        </div>
        <div class="card card-search-os mb-4 px-0 py-0" style="border-radius:10px">
            <div class="card-body px-0 py-0">

                <div class="tec-search-v-icon">
                    <input   v-model="dataPagination.searchQuery" type="search" 
                    ref="searchbar" name="search" placeholder="recherche ..." 
                    id="tec-search-v" class="tec-search-v" style="background:transparent">
                    <i class="fa fa-search fa-md fa-fw" aria-hidden="true"></i>
                </div>
            </div>
        </div>
        <div class="card card-list-os" v-if="empty" v-loading="loading" >
            <div class="card-body text-center">
                <div  class="d-flex justify-content-center w-100 p-5">
                    <img  class="w-50" :src="require('../../../../assets/svg/empty_streec.svg')"   alt="" srcset="">
                </div>
                    <h6>Aucune donnée</h6>
            </div>
        </div>
        <div class="" v-if="!empty" v-loading="loading" >
            <div class="card card-list-os mb-3" style="border-radius:10px!important;box-shadow:0px 0px 4px 1px rgb(0,0,0,0.15) !important"  v-for="row in queriedData" :key="row.id">
                <div class="card-body py-3 pl-2 d-flex align-items-center justify-content-between" >
                    <div style="max-width:90%">
                        <h5 class="card-title  pb-2" style="font-size:0.9rem;font-weight:700;font-family:'Nunito'"> 
                            <span style="text-transform:uppercase;" class="pl-2">{{$moment(row.month.split('/')[0]+'-'+row.month.split('/')[1]).format('MMM YYYY')}} | {{row.articles.designation}}</span>  
                        </h5>
                        <div class="mt-n3 mobile-card-describe pl-2 d-flex justify-content-start flex-wrap" style="font-weight:500;text-transform:uppercase;font-size:0.75rem; color:rgba(62, 69, 81, .8);">
                            <span class=" item-mobile-spa">HT: {{row.ht |roundNumber | formatNumber}}</span>
                            <span class=" item-mobile-spa">TTC: {{row.ttc |roundNumber | formatNumber}}</span>
                            <span class=" item-mobile-spa">TS: {{row.ts |roundNumber | formatNumber}}</span>
                            <span class=" item-mobile-spa">{{configTs.tax1}}: {{row.tax1 | roundNumber |formatNumber}}</span>
                            <span class=" item-mobile-spa">{{configTs.tax2}}: {{row.tax2 | roundNumber |formatNumber}}</span>
                        </div> 
                    </div>
                    <div class="action">
                        <button class="btn-mob" @click="showAction(row)" >
                            <i class="color-normal-reversed fas fa-ellipsis-v"></i>
                        </button>
                    </div>    
                </div>
            </div>

            <div class="d-flex justify-content-end">
                <tec-pagination
                    v-model="dataPagination.pagination.currentPage"
                    :per-page="parseFloat(dataPagination.pagination.perPage)"
                    :total="dataPagination.pagination.total"
                ></tec-pagination>
            </div>
        </div>
        <mdb-modal  color="primary" centered size="sm" position="bottom" direction="bottom" :show="modalAction.show" @close="hideAction()">
            <mdb-modal-body class="px-0 py-0 "  >
                <mdb-list-group flush>
                    <mdb-list-group-item  
                    class="bg-primary white-text font-weight-bold" > 
                    <div>MENU</div> 
                    <button class="btn-mob" @click="hideAction()"> 
                    <i class="fas fa-times"></i> 
                    </button> 
                </mdb-list-group-item>
                <mdb-list-group-item  
                class="menu-tec-lg"
                    @click.native="addOrEdit(modalAction.content)"
                    v-if="$gate.hasPermission(['edit::price'])"

                    icon="edit"  style="font-weight:400;" >
                    <div class="pl-3 menu-mob-tec">Modifier la structure</div>
                    </mdb-list-group-item>
                <mdb-list-group-item  
                    class="menu-tec-lg"
                    @click.native="duplicate(modalAction.content)"
                    v-if="$gate.hasPermission(['create::price'])"

                    icon="copy"  style="font-weight:400;" >
                    <div class="pl-3 menu-mob-tec">Dupliquer la structure</div>
                    </mdb-list-group-item>
                <mdb-list-group-item  
                    class="menu-tec-lg text-danger"
                    v-if="$gate.hasPermission(['delete::price'])"

                    @click.native="deletePrice(modalAction.content.id)"   
                    icon="trash"   
                    style="font-weight:400;" >
                    <div class="pl-3 menu-mob-tec">Supprimer la structure</div>
                </mdb-list-group-item>
                </mdb-list-group>
            </mdb-modal-body>
        </mdb-modal>
    </div>
</template>

<script>
import TecPagination from '@/tba/components/Pagination'
import {
    mdbBtn,
    mdbModal,  mdbModalBody,
    mdbListGroup, mdbListGroupItem ,
} from 'mdbvue'
import { mdbDropdown, mdbDropdownItem, mdbDropdownMenu, mdbDropdownToggle } from 'mdbvue';
export default {
    components:{
        mdbBtn,
        mdbModal,  mdbModalBody,
        mdbListGroup, mdbListGroupItem ,
        mdbDropdown, mdbDropdownItem, mdbDropdownMenu, mdbDropdownToggle,
        TecPagination
    },

    props:{
        empty:{
            type:Boolean,
            default:false
        },
        configTs:{
            type:Object,
            default:()=> {}
        },
        loading:{
            type:Boolean,
            default:false
        },
        dataPagination:{
            type:Array,
            default: ()=>[]
        },
        data:{
            type:Array,
            default:() => []
        },
        queriedData:{
            type: Array,
            default: () => []
        },
    },

    data(){
        return {
            modalAction:{
                show:false,
                content:{}
            }
        }
    },
    methods:{
        showAction(data){
            this.modalAction.content  = data
            this.modalAction.show = true
        },
        hideAction(){
            this.modalAction.content  = {}
            this.modalAction.show = false
        },

        addOrEdit(data = null){
            this.modalAction.show = false
            this.$emit('add_or_edit',data)
        },

        duplicate(data){
            this.modalAction.show = false
            this.$emit('duplicate',data)
        },

         duplicateAll(){
            this.modalAction.show = false
            this.$emit('duplicate_all')
        },
        
        selected(e){
            this.$emit('per_page_selected',e)
        },

        deletePrice(id)
        {
            this.modalAction.show = false
            this.$emit('delete_price', id)
		},
    }
}
</script>

<style lang="scss" >

</style>