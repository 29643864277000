<template>
  <div>
  <div class="d-flex justify-content-between align-items-center mb-n2">
      <h4 style="font-weight: 500">Structures de prix</h4> 
      <div class="mr-2">
              <mdb-btn
                @click="addOrEdit(null)"
                icon="plus"
                tag="a"
                size="sm"
                color="primary"
                class="mr-0"
                v-if="$gate.hasPermission(['create::price'])"
              ></mdb-btn>
              <mdb-dropdown btn-group split dropleft>
                <mdb-dropdown-toggle
                  slot="toggle"
                  color="primary"
                  size="sm"
                  class="px-2 py-2"
                ></mdb-dropdown-toggle>
                <mdb-dropdown-menu>
                  <mdb-dropdown-item @click="addOrEdit(null)"
                    ><i class="fas fa-plus"></i> Ajouter une structure</mdb-dropdown-item
                  >
                  <mdb-dropdown-item @click.native="duplicateAll()">
                    <i class="fas fa-copy"></i> Dupliquer les
                    structures</mdb-dropdown-item
                  >
                </mdb-dropdown-menu>
              </mdb-dropdown>
            </div>
    </div>
    <hr class="" />
    <mdb-row>
      <mdb-col sm="12" md="12" lg="12" class="mx-auto">
        <mdb-card cascade>
          <div
          v-if="false"
            class="z-depth-1 pl-2 pt-1 pb-1 d-flex box-radius justify-content-between align-items-center"
          >
            <div
              class="title-card-tec card-header-title color-primary-reversed"
              style="font-weight: 400"
            >
              Structures de prix
            </div>
            <div class="mr-2">
              <mdb-btn
                @click="addOrEdit(null)"
                icon="plus"
                tag="a"
                size="sm"
                color="primary"
                class="mr-0"
              ></mdb-btn>
              <mdb-dropdown btn-group split dropleft>
                <mdb-dropdown-toggle
                  slot="toggle"
                  color="primary"
                  size="sm"
                  class="px-2 py-2"
                ></mdb-dropdown-toggle>
                <mdb-dropdown-menu>
                  <mdb-dropdown-item @click="addOrEdit(null)"
                    ><i class="fas fa-plus"></i> Ajouter une structure</mdb-dropdown-item
                  >
                  <mdb-dropdown-item @click.native="duplicateAll()">
                    <i class="fas fa-copy"></i> Dupliquer les
                    structures</mdb-dropdown-item
                  >
                </mdb-dropdown-menu>
              </mdb-dropdown>
            </div>
          </div>

          <mdb-card-body :class="'card-reversed'" cascade v-loading="loading">
            <mdb-row>
              <mdb-col sm="12" md="4" class="">
                <mdb-select
                  flipOnScroll
                  label="Nombre par page"
                  outline
                  class=""
                  @getValue="selected"
                  v-model="dataPagination.pagination.perPageOptions"
                />
              </mdb-col>
              <mdb-col sm="12" md="4" class=""> </mdb-col>
              <mdb-col sm="12" md="4" class="">
                <mdb-input
                  inputClass="border-reversed color-normal-reversed"
                  labelClass=" color-normal-reversed"
                  icon="search"
                  outline
                  type="text"
                  v-model="dataPagination.searchQuery"
                  placeholder="Rechercher"
                  aria-label="Search"
                />
              </mdb-col>
            </mdb-row>

            <mdb-tbl responsiveMd >
              <mdb-tbl-head>
                <tr class="color-normal-reversed">
                  <th v-for="column in data.columns" :key="column.name">
                    <span v-if="column.field === 'tax1'">
                      {{ configTs.tax1 }}
                    </span>
                    <span v-else-if="column.field === 'tax2'">
                      {{ configTs.tax2 }}
                    </span>
                    <span v-else>
                      {{ column.label }}
                    </span>
                  </th>
                </tr>
              </mdb-tbl-head>
              <mdb-tbl-body>
                <tr
                  class="tec-table-row color-normal-reversed"
                  v-for="row in queriedData"
                  :key="row.name"
                >
                  <td class="tec-table-cell text-capitalize">
                    {{
                      $moment(
                        row.month.split("/")[0] + "-" + row.month.split("/")[1]
                      ).format("MMM YYYY")
                    }}
                  </td>
                  <td class="tec-table-cell">{{ row.articles.designation }}</td>
                  <td class="tec-table-cell text-align-right">
                    {{ row.ht | formatNumber }}
                  </td>
                  <td class="tec-table-cell text-align-right">
                    {{ row.ttc | formatNumber }}
                  </td>
                  <td class="tec-table-cell text-align-right">
                    {{ row.ts | formatNumber }}
                  </td>
                  <td class="tec-table-cell text-align-right">
                    {{ row.tax1 | formatNumber }}
                  </td>
                  <td class="tec-table-cell text-align-right">
                    {{ row.tax2 | formatNumber }}
                  </td>
                  <td class="d-flex justify-content-end">
                    <el-dropdown trigger="click">
                      <span
                        class="el-dropdown-link"
                        style="cursor: pointer; font-weight: 500"
                      >
                        Actions<i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                      <el-dropdown-menu class="dropdown-el-tec" slot="dropdown">
                        <el-dropdown-item
                          @click.native="addOrEdit(row)"
                          class="dropdown-item-el-tec"
                          icon="el-icon-edit"
                          v-if="$gate.hasPermission(['edit::price'])"

                          >Modifier</el-dropdown-item
                        >
                        <el-dropdown-item
                        v-if="$gate.hasPermission(['create::price'])"

                          @click.native="duplicate(row)"
                          class="dropdown-item-el-tec"
                          icon="el-icon-document-copy"
                          >Dupliquer</el-dropdown-item
                        >
                        <el-dropdown-item
                        v-if="$gate.hasPermission(['delete::price'])"

                          @click.native="deletePrice(row.id)"
                          class="dropdown-item-el-tec"
                          icon="el-icon-delete"
                          >Supprimer</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </td>
                </tr>
              </mdb-tbl-body>
            </mdb-tbl>
            <div v-if="empty">
              <div
                class="d-flex justify-content-around align-items-center color-normal-reversed"
                style="padding: 50px"
              >
                <div>
                  <p>
                    Pour ajouter une structure de prix, cliquez sur le bouton
                    <mdb-btn
                      class="z-depth-0"
                      style="cursor: inherit"
                      tag="a"
                      color="primary"
                      icon="plus"
                      size="sm"
                      small
                    ></mdb-btn>
                    en haut à votre droite.
                  </p>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <tec-pagination
                v-model="dataPagination.pagination.currentPage"
                :per-page="parseFloat(dataPagination.pagination.perPage)"
                :total="dataPagination.pagination.total"
              ></tec-pagination>
            </div>
          </mdb-card-body>
        </mdb-card>
      </mdb-col>
    </mdb-row>
  </div>
</template>

<script>
import {
  mdbCard,
  mdbCardBody,
  mdbBtn,
  // mdbView,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbRow,
  mdbCol,
  mdbSelect,
  mdbInput,
} from "mdbvue";
import TecPagination from "@/tba/components/Pagination";
import { mdbDropdown, mdbDropdownItem, mdbDropdownMenu, mdbDropdownToggle } from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbBtn,
    // mdbView,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbRow,
    mdbCol,
    mdbSelect,
    mdbInput,
    mdbDropdown,
    mdbDropdownItem,
    mdbDropdownMenu,
    mdbDropdownToggle,
    TecPagination,
  },
  props: {
    empty: {
      type: Boolean,
      default: false,
    },
    configTs: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    dataPagination: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    queriedData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    addOrEdit(data = null) {
      this.$emit("add_or_edit", data);
    },

    duplicate(data) {
      this.$emit("duplicate", data);
    },

    duplicateAll() {
      this.$emit("duplicate_all");
    },

    selected(e) {
      this.$emit("per_page_selected", e);
    },

    deletePrice(id) {
      this.$emit("delete_price", id);
    },
  },
};
</script>
